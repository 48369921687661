import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import UploadIcon from '@material-ui/icons/AddAPhoto';
import Close from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import confirm from 'lib/confirm';
import notify from 'lib/notify';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FileUploadTuple } from 'utils/constants';
import * as yup from 'yup';
import { CreateMeetingParams } from './CreateMeetingModal';

const formStyles: { [x: string]: CSSProperties } = {
  bgImg: {
    width: '100%',
    marginBottom: '20px'
  },
  bgImgTitle: {
    fontSize: '14px',
    color: '#000',
    marginBottom: '10px',
    marginTop: '20px'
  },
  uploadBgImg: {
    width: '100%',
    height: '170px',
    backgroundColor: '#f7f7f7',
    borderRadius: '7px',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  },
  showBgImg: {
    width: '100%',
    height: '170px',
    borderRadius: '7px',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  },

  prev: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  prevImg: {
    width: '120px',
    height: '120px',
    borderRadius: '7px',
    position: 'relative',
    overflow: 'hidden',
    marginRight: 10,
    display: 'flex',
    backgroundColor: '#f7f7f7',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  close: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    width: '20px',
    height: '20px',
    backgroundColor: '#ff0000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 0,
    outline: 0,
    boxShadow: 'none',
    borderRadius: 100,
  }
}

const validationSchema = yup.object().shape({
  topic: yup.string().required(),
  type: yup.string().optional(),
  requirements: yup.string().optional(),
  learningGoals: yup.string().optional(),
});

const DefaultMeetingType = 'education_unit';

type FormData = {
  topic: string;
  requirements: string;
  learningGoals: string;
  type: string;
  backgroundImage?: FileUploadTuple;
  photos?: FileUploadTuple[];
};

interface Props {
  isVisible: boolean;
  styles: any;
  isCopyMeetingModal: boolean;
  backgroundImage?: string; //think about removing this as both uses just come from the meetingDetails
  meetingDetails?: CreateMeetingParams;
  advanceFn?: (props: FormData) => void;
  prevFn: () => void;
}

function CreateMeetingBasicChunk(props: Props) {
  const {
    isVisible,
    styles,
    isCopyMeetingModal,
    backgroundImage,
    meetingDetails,
    advanceFn,
    prevFn,
  } = props;
  const [topic, setTopic] = useState<string>(meetingDetails ? meetingDetails.topic : '');
  const [type, setType] = useState<string>(
    meetingDetails && meetingDetails.type ? meetingDetails.type : DefaultMeetingType,
  );
  const [requirements, setRequirements] = useState<string>(
    meetingDetails ? meetingDetails.requirements : '',
  );
  const [learningGoals, setLearningGoals] = useState<string>(
    meetingDetails ? meetingDetails.learningGoals : '',
  );

  const initialPhotos:FileUploadTuple[] = meetingDetails?.photos ? meetingDetails?.photos.sort((ph1: any, ph2: any) => ph1.order - ph2.order).map(img => ({ url: img?.url, file: null })) : [];
  const [bgImage, setBgImage] = useState<FileUploadTuple>( {url:backgroundImage, file:null});
  const [images, setImages] = useState<FileUploadTuple[]>(initialPhotos);
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      requirements: requirements,
      learningGoals,
      type,
      topic,
    },
  });


  const dragItem = useRef<number>();
  const dragOverItem = useRef<number>();

  const handleSubmitCheck = handleSubmit(async (values) => {
    values.type = type;
    values.backgroundImage = bgImage;
    values.photos = images;
    advanceFn(values);
  });

  const changeType = (ev) => {
    setType(ev.target.value as string);
  };

  const handleTopicInput = (e) => {
    setTopic(e.target.value);
  };

  const handleReqInput = (e) => {
    setRequirements(e.target.value);
  };

  const handleLearnInput = (e) => {
    setLearningGoals(e.target.value);
  };

  const handleBgImageChange = (e) => {
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files
      let url = URL.createObjectURL(file);
      setBgImage({ file, url })
    };
  }

  const handleImageChange = (e: any) => {
    if (e.target.files.length + images.length > 4) {
      notify("You can upload only 4 images.");
      e.preventDefault();
      return;
    }
    if (e.target.files.length) {
      const images:FileUploadTuple[] = [];
      for (let file of e.target.files) {
        let url = URL.createObjectURL(file)
        images.push({ file, url, })
      }
      setImages((prev) =>([...prev, ...images]))
    }
  }

  const removeBackgroundImage = () => {
    confirm({
      message: 'Are you sure?',
      onAnswer: (ans) => { if (ans) setBgImage({ file: null, url: null }) },
      title: 'Remove Background image'
    })
  }

  const removeTemplatePhoto = (index: number) => {
    confirm({
      message: 'Are you sure?',
      onAnswer: (ans) => { if (ans) setImages((prev) => prev.filter((_v, i) => i !== index)) },
      title: 'Remove photo'
    })
  }

  useEffect(() => {
    if (meetingDetails) {
      setTopic(meetingDetails.topic);
      setRequirements(meetingDetails.requirements);
      setLearningGoals(meetingDetails.learningGoals);
      if(meetingDetails.type) {
        setType(meetingDetails.type);
      }
    } else {
      setType(DefaultMeetingType);
    }
  }, [meetingDetails]);

  const dragStart = (position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (position: number) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...images];
    const dragItemContent = copyListItems[dragItem.current as number];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setImages(copyListItems);
  };

  return (
    isVisible && (
      <>
        <InputLabel>Class type</InputLabel>
        <Select value={type} name={'type'} {...register('type', {onChange: changeType})}
          disabled={true} hidden={true}>
          <MenuItem value="education_unit">
            Default
          </MenuItem>
          <MenuItem value="small_group">
            Class for students - more controls to enforce teaching, recording automatically
          </MenuItem>
          <MenuItem value="open_social">
            Social group - having fun learning. recording off by default
          </MenuItem>
        </Select>
        {errors.type && <div style={styles.error}>{errors.type.message}</div>}
        <div style={{ marginBottom: '10px' }}>
          <TextField
            autoFocus
            label={'Class Topic - what is the headline for this class?'}
            type={'text'}
            fullWidth
            {...register('topic', { onChange: handleTopicInput })}
          />
          {errors.topic && <div style={styles.error}>{errors.topic.message}</div>}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <TextField
            autoFocus
            label={'Requirements - do students need to bring anything to class?'}
            type={'text'}
            name={'requirements'}
            value={requirements}
            fullWidth
            multiline={true}
            minRows={3}
            {...register('requirements', { onChange: handleReqInput })}
          />
          {errors.requirements && <div style={styles.error}>{errors.requirements.message}</div>}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <TextField
            autoFocus
            label={
              'learning goals - what should students expect to learn after completing this class?'
            }
            type={'text'}
            name={'learningGoals'}
            value={learningGoals}
            fullWidth
            multiline={true}
            minRows={3}
            {...register('learningGoals', { onChange: handleLearnInput })}
          />
          {errors.learningGoals && <div style={styles.error}>{errors.learningGoals.message}</div>}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Box style={formStyles.bgImg}>
            <Typography variant="h4" style={formStyles.bgImgTitle}>Background Image</Typography>
            {bgImage.url ? <Box style={formStyles.showBgImg}>
              <img src={bgImage.url} style={formStyles.img} alt="background image" />
              <button type='button' style={formStyles.close} onClick={removeBackgroundImage}>
                <Close style={{ fontSize: '18px', color: '#ffffff' }} />
              </button>
            </Box> : <Box component="label" style={formStyles.uploadBgImg}>
              <UploadIcon style={{ fontSize: '38px', color: '#222' }} />
              <input hidden onChange={handleBgImageChange} accept="image/*" type="file" />
            </Box>
            }
          </Box>
          <Box style={formStyles.bgImg}>
            <Typography variant="h4" style={formStyles.bgImgTitle}>Add Class Template Images</Typography>
            {images.length > 0 && <Alert severity="info" style={{ marginBottom: '10px' }}>You can reorder images by clicking and dragging them into the position you want it in.  Remember to save the changes by clicking update</Alert>}
            <Box style={formStyles.prev}>
              {images.map((image, i) => (<div
                key={i}
                draggable
                onDragStart={() => dragStart(i)}
                onDragEnter={() => dragEnter(i)}
                onDragEnd={drop}
                style={{ ...formStyles.prevImg, cursor: 'grabbing' }}
              >
                <img src={image.url} style={formStyles.img} alt={'image' + i} />
                <button type='button' style={formStyles.close} onClick={() => removeTemplatePhoto(i)}>
                  <Close style={{ fontSize: '18px', color: '#ffffff' }} />
                </button>
              </div>))}
              {images.length < 4 && <Box component="label" style={{ ...formStyles.prevImg, cursor: 'pointer' }}>
                <UploadIcon style={{ fontSize: '24px', color: '#222' }} />
                <input hidden onChange={handleImageChange} accept="image/*" multiple type="file" />
              </Box>}
            </Box>
          </Box>
        </div>
        {isCopyMeetingModal && backgroundImage && (
          <div>
            <h5>Copying background image</h5>
            <img src={backgroundImage} />
          </div>
        )}
        <DialogActions>
          <Button variant="contained" color="primary" onClick={prevFn}>
            Cancel
          </Button>
          <Button onClick={handleSubmitCheck} variant="contained" color="primary">
            Next
          </Button>
        </DialogActions>
      </>
    )
  );
}

export default CreateMeetingBasicChunk;
