import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CreateMeetingParams } from './CreateMeetingModal';

type FormData = {
  isPrivate: boolean;
  classSize: number;
  emailsArr: string[];
};

interface Props {
  isVisible: boolean;
  isCopyMeetingModal: boolean;
  emailsArr: string[];
  attendeesArr: string[];
  setEmailsArr: (arr: string[]) => void;
  styles: any;
  disabled: boolean;
  meetingDetails: CreateMeetingParams;
  advanceFn?: (props: FormData) => void;
  prevFn: () => void;
}

const validationSchema = yup.object().shape({
  classSize: yup
    .number()
    .min(1)
    .max(100),
});

function CreateMeetingInvitesChunk(props: Props) {
  const {
    isVisible,
    isCopyMeetingModal,
    emailsArr,
    attendeesArr,
    setEmailsArr,
    styles,
    meetingDetails,
    disabled,
    advanceFn,
    prevFn,
  } = props;
  const [email, setEmail] = useState<string>('');
  const [copyAttendees, setCopyAttendees] = useState<boolean>(false);
  const [isPrivateChecked, setIsPrivateChecked] = useState<boolean>(true);

  useEffect(() => {
    if (meetingDetails) {
      setIsPrivateChecked(meetingDetails.isPrivate == true);
    }
  }, [meetingDetails]);

  const addEmail = (key: string) => {
    if (!['Enter', 'NumpadEnter'].includes(key)) return;
    const emails: string[] = emailsArr;
    emails.push(email);
    setEmailsArr(emails);
    setEmail('');
  };

  const renderAddedEmails = () => {
    const removeEmail = (email: string) => {
      let emails: string[] = emailsArr;
      emails = emails.filter((el) => email !== el);
      setEmailsArr(emails);
    };

    return emailsArr.map((email, i) => {
      return (
        <div style={styles.email} key={i}>
          {email}
          <div onClick={() => removeEmail(email)} style={{cursor:'pointer'}}>
          &nbsp;&nbsp;X
          </div>
        </div>
      );
    });
  };

  const handleCopyChange = (copy: boolean) => {
    setCopyAttendees(copy);
    if (copy) {
      setEmailsArr(_.concat(emailsArr, attendeesArr));
    } else {
      setEmailsArr(_.difference(emailsArr, attendeesArr));
    }
  };

  const changePrivate = (ev) => {
    setIsPrivateChecked(ev.target.checked);
  };

  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isPrivate: isPrivateChecked,
      classSize: 4,
    },
  });

  const handleSubmitCheck = handleSubmit(async (values) => {
    values.emailsArr = emailsArr;
    values.isPrivate = isPrivateChecked;
    //any special checks here?
    advanceFn(values);
  });

  return (
    isVisible && (
      <>
        <div style={{ marginTop: '20px' }}>
          If a class is public anyone can join.  if a class is private you will need to invite each person by email.
        </div>
        <div style={{ marginBottom: '10px' }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="isPrivate"
                  checked={isPrivateChecked}
                  {...register('isPrivate', {onChange: changePrivate})}
                />
              }
              label="Private"
            />
          </FormControl>
        </div>
        {!isPrivateChecked && (<>
          <div style={{ marginTop: '20px' }}>
            You can limit the size of public classes.
          </div>
        
          <div style={{ marginBottom: '10px' }}>
            <TextField
              label={'Class size'}
              type={'number'}
              name={'classSize'}
              defaultValue={4}
              InputProps={{
                inputProps: {
                  max: 100,
                  min: 1,
                },
              }}
              {...register('classSize')}
            />
            {errors.classSize && <div style={styles.error}>{errors.classSize.message}</div>}
            {errors.isPrivate && <div style={styles.error}>isPrivate err</div>}
            {errors.emailsArr && <div style={styles.error}>emailsArr err</div>}
          </div></>
        )}

        <div>
          <div style={{ marginTop: '20px' }}>
            Please enter the email address of anyone you would like to invite.
          </div>
          <TextField
            label={'Email Address'}
            type={'email'}
            value={email}
            onKeyDown={(e) => addEmail(e.key)}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </div>
        {isCopyMeetingModal && (
          <div>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Copy Attendees"
                    onChange={(e) => handleCopyChange(e.target.checked)}
                    checked={copyAttendees}
                    disabled={false}
                  />
                }
                label="Copy Attendees"
              />
            </FormControl>
          </div>
        )}
        <div style={styles.emailsContainer}>{renderAddedEmails()}</div>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={prevFn}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitCheck} disabled={disabled}>
            Create
          </Button>
        </DialogActions>
      </>
    )
  );
}

export default CreateMeetingInvitesChunk;
