import { getSignedRequestForUploadApiMethod, uploadFileUsingSignedPutRequestApiMethod } from "lib/api/team-member";
import notify from "lib/notify";
import { resizeImage } from "lib/resizeImage";

const uploadFile = async (prefix, file, width, height) => {
  if (!file) return null

  const fileName = file.name;
  const fileType = file.type;

  const bucket = process.env.BUCKET_FOR_AVATARS;
  //const prefix = `${user._id}`;

  try {
    const responseFromApiServerForUpload = await getSignedRequestForUploadApiMethod({
      fileName,
      fileType,
      prefix,
      bucket,
    });

    const resizedFile = await resizeImage(file, width, height);

    await uploadFileUsingSignedPutRequestApiMethod(
      resizedFile,
      responseFromApiServerForUpload.signedRequest,
      { 'Cache-Control': 'max-age=2592000', 'Content-Type': file.type },
    );
    return responseFromApiServerForUpload.url;
    // const { url } = await fetch(`https://picsum.photos/${width}/${height}`)
    // return url
  } catch (error) {
    notify(error);
    return null;
  }
};

export default uploadFile