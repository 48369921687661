import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PaymentType, RateType } from 'utils/constants';
import * as yup from 'yup';
import { CreateMeetingParams } from './CreateMeetingModal';


type FormData = {
  rate: number;
  paymentType: PaymentType;
  rateType: RateType;
  billTo: string;
};

interface Props {
  isVisible: boolean;
  canCharge: boolean;
  styles: any;
  meetingDetails?: CreateMeetingParams;
  advanceFn?: (props: FormData) => void;
  prevFn: () => void;
}


const validationSchema = yup.object().shape({
  rate: yup
    .number()
    .min(1)
    .max(250).when('paymentType', { is: value => [PaymentType.Invoice, PaymentType.PaidParticipant].includes(value), then: yup.number().required() }),
  rateType: yup.mixed<RateType>().when('paymentType', { is: value => [PaymentType.Invoice, PaymentType.PaidParticipant].includes(value), then: yup.string().required() }),
  paymentType: yup.mixed<PaymentType>().oneOf(Object.values(PaymentType)).required(),
  billTo: yup.string().email().when('paymentType', { is: PaymentType.Invoice, then: yup.string().email().required() }),
});


const defaultPaymentType = PaymentType.Free;

function getPaymentType(meetingDetails: CreateMeetingParams): PaymentType {
  if (meetingDetails) {
    if (meetingDetails.paymentType) {
      return meetingDetails.paymentType;
    }

    //legacy support
    if ((meetingDetails as any).isInvoice) {
      return PaymentType.Invoice;
    } else if (!(meetingDetails as any).isFree) {
      return PaymentType.Free;
    }
  }
  //meetingDetails if no meetingDetails this should be the default
  return defaultPaymentType;
}

const defaultRate = 30;

function CreateMeetingPaymentChunk(props: Props) {
  const { isVisible, canCharge, styles, meetingDetails, advanceFn, prevFn } = props;
  const [rate, setRate] = useState<number>(meetingDetails ? meetingDetails.rate : defaultRate);
  const [rateType, setRateType] = useState<RateType>(meetingDetails && meetingDetails.rateType ? meetingDetails.rateType : RateType.FlatRate);
  const [paymentType, setPaymentType] = useState<PaymentType>(getPaymentType(meetingDetails));
  const billTo = meetingDetails ? meetingDetails.billTo : '';

  useEffect(() => {
    if (meetingDetails) {
      setRate(meetingDetails.rate);
      setPaymentType(getPaymentType(meetingDetails));
    }
  }, [meetingDetails]);

  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      rate,
      rateType,
      paymentType,
      billTo,
    },
  });

  const handleSubmitCheck = handleSubmit(async (values) => {
    //any special checks here?
    switch (paymentType) {
      case PaymentType.Free:
        values.rate = 0;
        break;
    }
    advanceFn(values);
  }); //on invalid is second optional param here

  const changeType = (ev) => {
    if (canCharge || ev.target.value == PaymentType.Free) {
      setPaymentType(ev.target.value as PaymentType);
    }
  };

  const changeRateType = (ev) => {
    setRateType(ev.target.value as RateType);
  };

  function getRateHint(rateType: RateType) {
    switch (rateType) {
      case RateType.FlatRate:
        return 'total amount to invoice';
      case RateType.PerParticipant:
        return 'rate per participant';
    }
  }

  const rateHint = getRateHint(rateType);

  return (
    isVisible && (
      <>
        <div style={{ display: 'flex' }}>
          <div style={{ maxWidth: '90%', minWidth: "300px" }}>
            <Select
              defaultValue={paymentType}
              name={'type'}
              value={paymentType}
              {...register('paymentType', {onChange: changeType})}
              disabled={!canCharge}
            >
              <MenuItem value={PaymentType.Free}>Free</MenuItem>
              <MenuItem value={PaymentType.PaidParticipant}>Each participant pays before</MenuItem>
              <MenuItem value={PaymentType.Invoice}>Invoice sent after</MenuItem>
            </Select>
            {errors.paymentType && <div style={styles.error}>{errors.paymentType.message}</div>}

            {paymentType == PaymentType.Free && (
              <div style={{ marginTop: '10px' }}>
                This class is free. Anyone can join by confirming their spot.
              </div>
            )}
            {paymentType == PaymentType.Invoice && (
              <div>
                <div style={{ marginTop: '10px' }}>
                  This class will be paid for by an invoice
                </div>

                <div style={{ marginTop: '10px' }}>
                  <span>Choose invoice type </span>
                  <Select
                    defaultValue={rateType}
                    value={rateType}
                    name={'rateType'}
                    label={'rate type'}
                    {...register("rateType", {onChange: changeRateType})}
                  >
                    <MenuItem value={RateType.FlatRate}>Flat rate</MenuItem>
                    <MenuItem value={RateType.PerParticipant}>Rate per participant</MenuItem>
                  </Select>
                  {errors.rateType && <div style={styles.error}>{errors.rateType.message}</div>}
                </div>
                <div style={{ marginTop: '10px' }}>
                  <TextField
                    label={rateHint}
                    type={'number'}
                    name={'rate'}
                    fullWidth
                    defaultValue={rate}
                    {...register("rate")}
                    style={{ maxWidth: '500px' }}
                  />
                  {errors.rate && <div style={styles.error}>{errors.rate.message}</div>}
                </div>
                <div style={{ marginTop: '10px' }}>
                  <TextField
                    label={'email to send invoice'}
                    type={'text'}
                    name={'billTo'}
                    defaultValue={billTo}
                    fullWidth
                    {...register("billTo")}
                    style={{ maxWidth: '500px' }}
                  />
                  {errors.billTo && <div style={styles.error}>{errors.billTo.message}</div>}
                </div>
              </div>
            )}
            {paymentType == PaymentType.PaidParticipant && (
              <>
                <div style={{ marginTop: '10px' }}>
                  Each person that registers will pay the price you enter below
                </div>

                <div style={{ marginTop: '10px' }}>
                  <TextField
                    label={'Cost (per participant)'}
                    type={'number'}
                    name={'rate'}
                    fullWidth
                    defaultValue={rate}
                    {...register("rate")}
                    style={{ maxWidth: '300px' }}
                  />
                  {errors.rate && <div style={styles.error}>{errors.rate.message}</div>}
                </div>
              </>
            )}
          </div>
        </div>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={prevFn}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitCheck}>
            Next
          </Button>
        </DialogActions>

      </>
    )
  );
}

export default CreateMeetingPaymentChunk;
