import { Link, Step, StepLabel, Stepper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import uploadFile from 'helpers/uploadFile';
import { Externals, Meeting } from 'lib/store/meeting';
import { User } from 'lib/store/user';
import moment from 'moment-timezone';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { CSSProperties, useEffect, useState } from 'react';
import { FileUploadTuple, ImageUploadResults, PhotoUploadResults } from 'utils/constants';
import { createMeeting } from '../../lib/api/video';
import notify from '../../lib/notify';
import CreateMeetingBasicChunk from './CreateMeetingBasicChunk';
import CreateMeetingInvitesChunk from './CreateMeetingInvitesChunk';
import CreateMeetingPaymentChunk from './CreateMeetingPaymentChunk';
import CreateMeetingSchedulingChunk from './CreateMeetingScheduleChunk';

type Modify<T, R> = Omit<T, keyof R> & R;
export interface CreateMeetingParams extends Modify<Meeting, {
  _id?: string;
  fee?: string;
  externals?: Externals;
  state?: string;
  enableRecording?: boolean;
  customConfirmationMessage?: string;
  scheduledAt ?: Date;
  durationMinutes?: number;
  invoice?: string;
  createdBy?: Date;
  createdAt?: Date;
  emails: string[];
  date: string;
  time: string;
  tz: string;
  emailsArr: string[];
  backgroundImage: FileUploadTuple;
  photos: FileUploadTuple[];
}> {}

export interface FinalizedCreateMeetingParams extends Modify<CreateMeetingParams, {
  backgroundImage: string;
  photos: PhotoUploadResults[];
}> {}

export function FinalizeCreateParams(data:CreateMeetingParams, backgroundImage:string, photos:PhotoUploadResults[]):FinalizedCreateMeetingParams {
  return { ...data, backgroundImage, photos };
}

type Props = {
  modalOpen: boolean;
  isAdmin: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
  canCharge: boolean;
  meetingDetails?: Meeting;
  user: User;
};

const styles: Record<string, CSSProperties> = {
  emailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  email: {
    display: 'flex',
    width: 'fit-content',
    padding: '8px 25px',
    backgroundColor: '#f0faff',
    marginRight: '15px',
    marginBottom: '15px',
  },
  removeEmail: {
    marginLeft: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  error: {
    color: ' #cb4335 ',
  },
};

function getSteps() {
  return ['Basic Info', 'Details', 'Payment Settings', 'Invites'];
}

export const joinTimeAndDate = (date: string, time: string) => {
  return moment(date + ' ' + time).format();
}

//returns an almost meeting object, tz -> timezone, date+time -> scheduledAt
export const convertObjToMeeting = (params:CreateMeetingParams) : CreateMeetingParams => {
  //take photos from params and convert to array of urls
  const photos = params.photos.map((photo) => photo.url);
  const data: CreateMeetingParams = Object.assign(params, {backgroundImage:params.backgroundImage?.url, photos});
  if (data.date && data.time) {
    data.date = joinTimeAndDate(data.date, data.time);
  }
  data.tz = moment.tz.guess();
  data.duration = data.duration * 60;
  data.emails = data.emailsArr;
  data.classSize = data.isPrivate ? 0 : data.classSize;
  return data;
};

function MeetingToCreateParams(meeting:Meeting) : CreateMeetingParams {
  const data: CreateMeetingParams = Object.assign({}, meeting) as unknown as CreateMeetingParams;
  data.date = moment(data.scheduledAt).format('YYYY-MM-DD');
  data.time = moment(data.scheduledAt).format('HH:mm');
  data.tz = moment.tz.guess();
  data.duration = data.duration / 60;
  data.emailsArr = data.emails || [];
  data.classSize = data.isPrivate ? 0 : data.classSize;
  data.backgroundImage = { url: meeting?.backgroundImage };
  data.photos = meeting?.photos.map((photo) => ({ url: photo?.url }));
  return data;
}

const CreateMeetingModal = (props: Props) => {
  const { modalOpen, handleClose, onSuccess, canCharge, user } = props;
  const [meetingDetails, setMeetingDetails] = useState(MeetingToCreateParams(props.meetingDetails));
  const [disabled, setDisabled] = useState<boolean>(false);
  const [emailsArr, setEmailsArr] = useState<string[]>([]);
  const [attendeesArr, setAttendeesArr] = useState<string[]>([]);
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const isCopyMeetingModal = meetingDetails != undefined;
  const [meetingCreateParams, setMeetingCreateParams] = useState<CreateMeetingParams>({} as CreateMeetingParams);

  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    if (meetingDetails) {
      const { attendees, backgroundImage } = meetingDetails;
      setBackgroundImage(backgroundImage.url);
      if (attendees) {
        setAttendeesArr(attendees.map((row) => row.email));
      }
    }
  }, [meetingDetails]);

  const finishImageUploadWork = async (backgroundImage:FileUploadTuple, photos:FileUploadTuple[]) : Promise<ImageUploadResults> => {
    let backgroundImageUrl = backgroundImage?.url;
    if (backgroundImage?.file) {
      backgroundImageUrl = await uploadFile(user._id, backgroundImage?.file, 1024, 240)
    }
    let photosUrl = await Promise.all(photos.map(async (image, i) => {
      let url = image.url;
      let order = i + 1
      if (image.file) {
        url = await uploadFile(user._id, image.file, 1280, 1024)
      }
      return { url, order }
    }))
    return { backgroundImage:backgroundImageUrl, photos:photosUrl }
  }


  const createMeetingFn = async () => {
    if (disabled) {
      return;
    }

    NProgress.start();
    setDisabled(true);
    notify('Creating Class...');


    const data:CreateMeetingParams = convertObjToMeeting(meetingCreateParams);

    try {
      const { backgroundImage, photos } = await finishImageUploadWork(meetingCreateParams.backgroundImage, meetingCreateParams.photos);
      const values = FinalizeCreateParams(data, backgroundImage, photos);
      await createMeeting(values);
      notify('Class Created');
      setDisabled(false);
      handleClose();
      onSuccess && onSuccess();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      notify(error);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  function handleNext() {
    if (activeStep == getSteps().length - 1) {
      createMeetingFn();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  /*
  useEffect(() => {
    console.log('updated mcp', meetingCreateParams);
  }, [meetingCreateParams]);

  useEffect(() => {
    console.log('updated md', meetingDetails);
  }, [meetingDetails]);
  */

  const reportFn = (props) => {
    setMeetingCreateParams(Object.assign(meetingCreateParams, props));
    handleNext();
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep == 0 ? prevActiveStep : prevActiveStep - 1));
    if (activeStep == 0) {
      handleClose();
    }
    //const newDetails = convertObjToMeeting(meetingCreateParams); is it ok not converting back here?
    setMeetingDetails(meetingCreateParams);
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {isCopyMeetingModal ? 'Copy Class' : 'Create Class'}
      </DialogTitle>
      <DialogContent>
        {!isCopyMeetingModal && (<Alert severity='info'>Save time by creating a class based on one of your <Link onClick={() =>
                    Router.push(
                      `/profile/${user._id}/templates`,
                      `/profile/${user._id}/templates`,
                    )
                  }>templates</Link></Alert>)}
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <CreateMeetingBasicChunk
            isVisible={activeStep == 0}
            styles={styles}
            isCopyMeetingModal={isCopyMeetingModal}
            backgroundImage={backgroundImage}
            meetingDetails={meetingDetails}
            advanceFn={reportFn}
            prevFn={handlePrev}
          />
          <CreateMeetingSchedulingChunk
            isVisible={activeStep == 1}
            styles={styles}
            meetingDetails={meetingDetails}
            advanceFn={reportFn}
            prevFn={handlePrev}
          />
          <CreateMeetingPaymentChunk
            isVisible={activeStep == 2}
            styles={styles}
            canCharge={canCharge}
            meetingDetails={meetingDetails}
            advanceFn={reportFn}
            prevFn={handlePrev}
          />
          <CreateMeetingInvitesChunk
            isVisible={activeStep == 3}
            styles={styles}
            isCopyMeetingModal={isCopyMeetingModal}
            emailsArr={emailsArr}
            attendeesArr={attendeesArr}
            setEmailsArr={setEmailsArr}
            disabled={disabled}
            meetingDetails={meetingDetails}
            advanceFn={reportFn}
            prevFn={handlePrev}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateMeetingModal;
