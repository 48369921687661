import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField
} from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CreateMeetingParams } from './CreateMeetingModal';

type FormData = {
  date: string;
  time: string;
  duration: number;
};

interface Props {
  isVisible: boolean;
  styles: any;
  meetingDetails: CreateMeetingParams;
  advanceFn?: (props: FormData) => void;
  prevFn: () => void;
}

const validationSchema = yup.object().shape({
  date: yup.string().required(),
  time: yup.string().required(),
  duration: yup
    .number()
    .min(15)
    .max(360),
});

function CreateMeetingScheduleChunk(props: Props) {
  const { isVisible, styles, meetingDetails, advanceFn, prevFn } = props;
  const [date, setDate] = useState<string>(
    moment()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
  );
  const [time, setTime] = useState<string>(
    meetingDetails && meetingDetails.time ? meetingDetails.time : '08:00'
  );

  const [duration, setDuration] = useState<number>(meetingDetails && meetingDetails.duration ? meetingDetails.duration : 60);

  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: date,
      time: time,
      duration: duration,
    },
  });

  useEffect(() => {
    if (meetingDetails) {
      setDate(meetingDetails.date);
      setTime(meetingDetails.time);
      if(meetingDetails.duration) {
        setDuration(meetingDetails.duration);
      }
    }
  }, [meetingDetails]);

  const handleSubmitCheck = handleSubmit(async (values) => {
    //any special checks here?
    advanceFn(values);
  });

  return (
    isVisible && (
      <>
        <div style={{ display: 'flex' }}>
          <Grid item md={6} xs={12}>
            <p style={{ width: '65%', margin: 'auto' }}>
              When is the class and what is the duration?
            </p>
            <div style={{ width: '65%', margin: 'auto' }}>
              <TextField
                label={'Date'}
                type={'date'}
                name={'date'}
                defaultValue={date}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('date')}
                fullWidth
              />
              {errors.date && <div style={styles.error}>{errors.date.message}</div>}
            </div>
            <div style={{ width: '65%', margin: 'auto' }}>
              <TextField
                label={'Time'}
                type={'time'}
                name={'time'}
                defaultValue={time}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('time')}
                fullWidth
              />
              {errors.time && <div style={styles.error}>{errors.time.message}</div>}
            </div>
            <div style={{ width: '65%', margin: 'auto' }}>
              <TextField
                label={'Duration (minutes)'}
                type={'number'}
                name={'duration'}
                defaultValue={duration}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 360,
                    min: 15,
                    step: 5,
                  },
                }}
                {...register('duration')}
                fullWidth
              />
              {errors.duration && <div style={styles.error}>{errors.duration.message}</div>}
            </div>
          </Grid>
          <Grid item md={6} xs={12} style={{margin:'auto'}}>
            <FormGroup >
              <FormControlLabel
                control={<Switch checked={false} name="activityReport" />}
                label="Send teacher attendee activity report"
                disabled={true}
              />
              <FormControlLabel
                control={<Switch checked={false} name="parentReport" />}
                label="Send parent report"
                disabled={true}
              />
              <FormControlLabel
                control={<Switch checked={false} name="classDiscussion" />}
                label="Allow class discussion"
                disabled={true}
              />
            </FormGroup>
          </Grid>
        </div>
        <DialogActions style={{ marginTop: 'auto' }}>
          <Button variant="contained" color="primary" onClick={prevFn}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitCheck}>
            Next
          </Button>
        </DialogActions>
      </>
    )
  );
}

export default CreateMeetingScheduleChunk;
